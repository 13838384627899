import React from 'react'
import c from 'classnames'

import androidWalletPass from 'images/badge_web_black_en@3x.png'
import iosWalletPass from 'images/512px-Add_to_Apple_Wallet_badge.png'

const sizeClass = {
  sm: 'h-10',
  md: 'h-14',
}

const WalletButtons = ({ iosLink, androidLink, size = 'md', className}) => {
  if (!iosLink && !androidLink) {
    return null
  }

  return (
    <div className={c("py-2 flex gap-2 md:flex-row", {"flex-col": (size !== 'sm')},  className)}>
      {iosLink && (
        <span>
          <a href={iosLink} target="_blank" rel="noreferrer">
            <img
              src={iosWalletPass}
              alt="Omaha Zoo iOS wallet pass"
              className={`w-auto ${sizeClass[size]}`}
            />
          </a>
        </span>
      )}
      {androidLink && (
        <span>
          <a href={androidLink} target="_blank" rel="noreferrer">
            <img
              src={androidWalletPass}
              alt="Omaha Zoo Android wallet pass"
              className={`w-auto ${sizeClass[size]}`}
            />
          </a>
        </span>
      )}
    </div>
  )
}

export default WalletButtons
