import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { selectors } from '../../reducer'
import SelectDonation from './SelectDonation'
import SignInForm from '../../account/forms/SignInForm'
import * as actions from '../../actions'

function DonatePage({
  isAuthenticated,
  isGuestFlowPreferred,
  setGuestFlowPreferred,
  ...props
}) {
  const skipSignIn = isAuthenticated || isGuestFlowPreferred
  const redirectUrl = props.location.pathname + props.location.search

  return skipSignIn ? (
    <SelectDonation {...props} />
  ) : (
    <section className="auth-page">
      <div className="content">
        <h2>Thank you for supporting the Omaha Zoo</h2>
        <p>
          If you have an Omaha Zoo account, sign in for faster donations.
          <br />
          <a onClick={() => setGuestFlowPreferred(true)}>
            You can also continue as a guest
          </a>
          .
        </p>
        <SignInForm redirectUrl={redirectUrl} />
        <hr data-content="OR" />
        <button
          style={{ marginTop: 20 }}
          className="button-primary button-green-outline"
          onClick={() => setGuestFlowPreferred(true)}
        >
          Continue as Guest
        </button>
      </div>
    </section>
  )
}

function mapStateToProps(state) {
  return {
    isAuthenticated: selectors.isAuthenticated(state),
    isGuestFlowPreferred: selectors.isGuestFlowPreferred(state),
  }
}

const mapDispatchToProps = {
  setGuestFlowPreferred: actions.setGuestFlowPreferred,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(DonatePage)
