import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  modifyProps,
  toggle,
  togglePropTypes,
  onMount,
} from '@launchpadlab/lp-hoc'
import {
  SubmissionError,
  submit,
  change,
  isSubmitting,
  formValueSelector,
} from 'redux-form'
import * as apiActions from 'main/apiActions'
import ReactGA from 'react-ga4'
import {
  displaySubmitFailure,
  findFirstErrorField,
  set,
  splitFullName,
  flashErrorMessage,
} from 'utils'
import { flashSuccessMessage } from 'redux-flash'
import { CouponCodeForm, PaymentForm, SellerCodeForm } from '../forms'
import * as actions from '../actions'
import { DonationCheckoutDisplay, ToggleTip } from 'components'
import { OrderReceipt } from '../components'
import * as effects from 'main/effects'
import { selectors } from '../reducer'
import { scroller } from 'react-scroll'
import { get } from 'lodash'
import { GpiWrapper, GpiSubmit } from 'components/Gpi'
import { MEMBERSHIP_CHECKOUT_STEPS } from '../../types'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import SocietyHeading from '../../../layout/SocietyHeading'

const propTypes = {
  addDonationToCart: PropTypes.func.isRequired,
  billingCountry: PropTypes.string,
  clearSellerCodeAndCoupon: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  membershipForm: PropTypes.object.isRequired,
  primaryCountry: PropTypes.string,
  removeMembershipDonation: PropTypes.func.isRequired,
  setAddOnDiscounts: PropTypes.func.isRequired,
  setCoupon: PropTypes.func.isRequired,
  setMembershipTypeDiscounts: PropTypes.func.isRequired,
  skipBillingInfo: PropTypes.bool.isRequired,
  setSellerCode: PropTypes.func.isRequired,
  submitOrder: PropTypes.func.isRequired,
  submitOrderFailed: PropTypes.func.isRequired,
  submitOrderSucceeded: PropTypes.func.isRequired,
  submitPaymentAndOrder: PropTypes.func.isRequired,
  ...togglePropTypes(['sellerCodeActive', 'receiptFormActive']),
  submitForm: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  appliedGiftCardAmount: PropTypes.number,
  remainingGiftCardBalance: PropTypes.number,
  setGiftCard: PropTypes.func.isRequired,
}

const defaultProps = {
  appliedGiftCardAmount: 0,
  remainingGiftCardBalance: null,
}

function PaymentDetails({
  addDonationToCart,
  billingCountry,
  clearSellerCodeAndCoupon,
  flashErrorMessage,
  flashSuccessMessage,
  isSubmitting,
  membershipForm,
  primaryCountry,
  removeMembershipDonation,
  sellerCodeActive,
  selectedMembershipType,
  setAddOnDiscounts,
  setCoupon,
  setMembershipTypeDiscounts,
  setSellerCode,
  skipBillingInfo,
  submitOrder,
  submitPaymentAndOrder,
  submitOrderFailed,
  submitOrderSucceeded,
  toggleSellerCodeActive,
  submitForm,
  changeForm,
  appliedGiftCardAmount,
  remainingGiftCardBalance,
  setGiftCard,
  selectedMembershipDetails,
}) {
  const primaryMember = get(membershipForm, 'membershipDetails.primaryMember')
  const hasAddressCountry =
    primaryMember && primaryMember.address && primaryMember.address.country
  const initialValuesPrimaryMember = hasAddressCountry
    ? primaryMember
    : set('address.country', 'US', primaryMember)

  const handleSubmit = ({
    cardToken,
    expirationDate,
    lastFourDigits,
    cardType,
    // maskedCardNumber, // not currently used but could be sent to server
  }) => {
    // https://redux-form.com/8.3.0/docs/api/props.md/#-code-change-field-string-value-any-function-code-

    // update the form's card token with the temporary payment token from GPI
    // and add any other payment-related fields that our server needs to know about
    changeForm('membership-payment', 'cardToken', cardToken)
    changeForm('membership-payment', 'expirationDate', expirationDate)
    changeForm('membership-payment', 'lastFourDigits', lastFourDigits)
    changeForm('membership-payment', 'cardType', cardType)
    // submit the form, which triggers other form validations and then ultimately submits to our server
    submitForm('membership-payment')
  }

  useEffect(() => {
    ReactGA.event('begin_checkout', {
      currency: 'USD',
      value: selectedMembershipType?.cost,
      items: [
        ...(membershipForm.addOns?.map((item) => ({
          item_id: item.addOnId,
          item_name: item.displayName,
          item_category: 'membership',
          item_category2: item.addOnType,
          price: item.cost,
          quantity: item.quantity,
        })) || []),
        {
          item_id: selectedMembershipType.id,
          item_name: selectedMembershipType.displayName,
          item_category: 'membership',
          item_category2: 'membership',
          price: selectedMembershipType?.cost,
          quantity: 1,
        },
      ],
    })
  }, [membershipForm])

  const donationLevels = selectedMembershipType.donationLevels
    .filter(({ customAmount }) => !customAmount)
    .map(({ displayName, amount }) => ({
      label: displayName.replace(',', ''),
      value: amount,
    }))
    .sort((l1, l2) => l1.value - l2.value)

  const { minAmount, maxAmount } =
    selectedMembershipType.donationLevels.find(
      ({ customAmount }) => customAmount
    ) || {}

  return (
    <SocietyCheckoutLayout
      title="Payment Info"
      backUrl="/membership/add-ons"
      progress={{ steps: MEMBERSHIP_CHECKOUT_STEPS, currentStep: 3 }}
      summary={<OrderReceipt />}
      ContinueButton={skipBillingInfo ? null : <GpiSubmit />}
      onContinue={() => submitForm('membership-payment')}
      disabled={isSubmitting}
      idme={false}
    >
      <GpiWrapper
        onTokenSuccess={handleSubmit}
        isDisabled={isSubmitting}
        gpiFormHasBeenRendered={!skipBillingInfo}
        gpiConfigType="membership"
      >
        {selectedMembershipType.enableDonation && (
          <DonationCheckoutDisplay
            donation={membershipForm.donation}
            header={selectedMembershipType.donationHeader}
            coverImg={selectedMembershipType.donationImage}
            levels={donationLevels}
            text={selectedMembershipType.donationText}
            addDonation={addDonationToCart}
            removeDonation={removeMembershipDonation}
            min={minAmount}
            max={maxAmount}
          />
        )}
        <div className="p-5 bg-white shadow">
          <div className="flex items-center gap-5">
            <SocietyHeading>Seller Code (Optional)</SocietyHeading>
            <ToggleTip
              allowHTML
              className="font-light font-roboto"
              content="If you have been given a Seller Code, please check the box below and enter the code. If your company offers a discount/payroll deduction, you will then be asked to enter your discount code and employee ID (if applicable). If you are not a verified employee of the company corresponding to the discount code used, your membership will be suspended, and you will be responsible to remit in full the remaining balance due for your membership to receive member benefits."
            >
              <button
                className="w-8 h-8 mt-1 text-lg text-white border-none rounded-full cursor-pointer bg-secondary-500 font-roboto"
                aria-label="tooltip"
                type="button"
              >
                ?
              </button>
            </ToggleTip>
          </div>

          <div className="flex items-center gap-3 mb-5">
            <input
              name="sellerCodeActive"
              id="sellerCodeActive"
              type="checkbox"
              className="mr-0"
              aria-label="checkbox if you have seller code"
              onChange={(e) => {
                if (!e.target.checked || membershipForm.coupon.couponCode) {
                  clearSellerCodeAndCoupon()
                  effects
                    .updateCheckout({
                      ...membershipForm,
                      coupon: {},
                      sellerCode: {},
                    })
                    .then((response) => {
                      flashSuccessMessage('Seller code has been removed')
                      setMembershipTypeDiscounts(response)
                      setAddOnDiscounts(response)
                    })
                    .catch(() =>
                      flashErrorMessage(
                        'There was a problem removing the seller code. Please try again.'
                      )
                    )
                }
                toggleSellerCodeActive()
              }}
              checked={sellerCodeActive}
            />
            <label
              htmlFor="sellerCodeActive"
              aria-label="Seller Code"
              className="mb-0"
              type="checkbox"
            >
              I have a Seller Code
            </label>
          </div>
          {sellerCodeActive && (
            <SellerCodeForm
              onSubmit={effects.validateSellerCode}
              onSubmitSuccess={(params) => {
                flashSuccessMessage('Seller code has been added')
                setSellerCode(params)
              }}
              initialValues={membershipForm}
              showButton={!membershipForm.sellerCode.sellerCode}
            />
          )}
          {sellerCodeActive && membershipForm.sellerCode.sellerCode && (
            <CouponCodeForm
              onSubmit={(params) =>
                effects.validateCouponCode(params).catch((err) => {
                  throw new SubmissionError({
                    _error: err.response.errors.errors.message,
                  })
                })
              }
              onSubmitSuccess={(response) => {
                flashSuccessMessage('Discount code has been applied')
                setCoupon(response)
                setMembershipTypeDiscounts(response)
                setAddOnDiscounts(response)
              }}
              couponLabel="Discount Code"
              initialValues={membershipForm}
              requiresEmployeeId={membershipForm.sellerCode.requiresEmployeeId}
              requiresCoupon={membershipForm.sellerCode.requiresCoupon}
            />
          )}
        </div>

        {!sellerCodeActive && (
          <div className="p-5 bg-white shadow">
            <SocietyHeading>Coupon Code</SocietyHeading>
            <CouponCodeForm
              onSubmit={(params) =>
                effects.validateCouponCode(params).catch((err) => {
                  throw new SubmissionError({
                    _error: err.response.errors.errors.message,
                  })
                })
              }
              onSubmitSuccess={(response) => {
                flashSuccessMessage('Coupon code has been applied')
                setCoupon(response)
                setMembershipTypeDiscounts(response)
                setAddOnDiscounts(response)
              }}
              couponLabel="Coupon Code"
              initialValues={membershipForm}
              requiresEmployeeId={false}
              requiresCoupon={true}
              disabled={sellerCodeActive}
            />
          </div>
        )}

        <PaymentForm
          billingCountry={billingCountry}
          primaryCountry={primaryCountry}
          onSubmit={skipBillingInfo ? submitOrder : submitPaymentAndOrder}
          onSubmitFail={submitOrderFailed}
          onSubmitSuccess={submitOrderSucceeded}
          initialValues={{
            primaryMember: initialValuesPrimaryMember,
            country: initialValuesPrimaryMember.address.country,
          }}
          skipBillingInfo={skipBillingInfo}
          appliedGiftCardAmount={appliedGiftCardAmount}
          remainingGiftCardBalance={remainingGiftCardBalance}
          onGiftCardApply={(giftCardNumber) => {
            return effects.updateCheckout({
              ...membershipForm,
              giftCard: { giftCardNumber },
            })
          }}
          onGiftCardApplySuccess={setGiftCard}
          onGiftCardApplyFail={(err) => flashErrorMessage(err.errors.message)}
          onGiftCardRemove={() => {
            return effects.updateCheckout({
              ...membershipForm,
              giftCard: { giftCardNumber: null },
            })
          }}
          onGiftCardRemoveSuccess={setGiftCard}
          onGiftCardRemoveFail={(err) => flashErrorMessage(err.errors.message)}
        />
      </GpiWrapper>
    </SocietyCheckoutLayout>
  )
}

PaymentDetails.propTypes = propTypes
PaymentDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  const orderTotals = selectors.orderTotals(state)
  return {
    billingCountry: formValueSelector('membership-payment')(state, 'country'),
    isSubmitting: isSubmitting('membership-payment')(state),
    fnboDiscounts: selectors.fnboDiscounts(state),
    membershipForm: selectors.membershipForm(state),
    membershipType: selectors.selectedMembershipType(state),
    primaryCountry: formValueSelector('membership-payment')(
      state,
      'primaryMember.address.country'
    ),
    selectedMembershipDetails: selectors.selectedMembershipDetails(state),
    selectedMembershipType: selectors.selectedMembershipType(state),
    skipBillingInfo: Number(orderTotals.total) === 0,
    orderTotals,
    appliedGiftCardAmount: selectors.appliedGiftCardAmount(state),
    remainingGiftCardBalance: selectors.remainingGiftCardBalance(state),
  }
}

const mapDispatchToProps = {
  clearSellerCodeAndCoupon: actions.clearSellerCodeAndCoupon,
  fetchAddOns: apiActions.fetchAddOns,
  fetchFnboDiscounts: apiActions.fetchFnboDiscounts,
  fetchMembershipTypes: apiActions.fetchMembershipTypes,
  flashSuccessMessage,
  flashErrorMessage,
  goToConfirmation: actions.goToConfirmation,
  removeMembershipDonation: actions.removeMembershipDonation,
  setAddOnDiscounts: actions.setAddOnDiscounts,
  setCoupon: actions.setCoupon,
  setMembershipDonation: actions.setMembershipDonation,
  setMembershipTypeDiscounts: actions.setMembershipTypeDiscounts,
  setOrder: actions.setOrder,
  setSelectedMembershipDetails: actions.setSelectedMembershipDetails,
  setSellerCode: actions.setSellerCode,
  submitForm: submit,
  changeForm: change,
  setGiftCard: actions.setGiftCard,
  clearGiftCard: actions.clearGiftCard,
}

function modify({
  goToConfirmation,
  membershipForm,
  selectedMembershipType,
  setOrder,
  setMembershipDonation,
  clearGiftCard,
}) {
  return {
    addDonationToCart: ({ level }) => {
      ReactGA.event('add_to_cart', {
        currency: 'USD',
        value: level,
        items: [
          {
            price: level,
            quantity: 1,
            item_category: 'membership',
            item_category2: 'donation',
          },
        ],
      })
      setMembershipDonation({ amount: level })
    },
    submitOrder: (params) => {
      const memberInfo = set(
        'membershipDetails.primaryMember',
        params.primaryMember,
        membershipForm
      )
      return effects.createOrder({
        ...memberInfo,
        fnboDiscount: false,
      })
    },
    submitPaymentAndOrder: (params) => {
      const memberInfo = set(
        'membershipDetails.primaryMember',
        params.primaryMember,
        membershipForm
      )

      const paymentResponse = {
        cardToken: params.cardToken,
        expirationDate: params.expirationDate,
        lastFourDigits: params.lastFourDigits,
        cardType: params.cardType,
        fullName: params.nameOnCard,
        ...splitFullName(params.nameOnCard),
        streetAddress: params.address,
        zip: params.zip,
        email: memberInfo.membershipDetails.primaryMember.email,
      }
      return effects.createOrder({
        paymentResponse,
        ...memberInfo,
        fnboDiscount: false,
      })
    },
    submitOrderFailed: (errors, dispatch, submitError, { syncErrors }) => {
      displaySubmitFailure(errors, dispatch, submitError)

      // Scroll UI to error field (if found)
      const firstErrorField = findFirstErrorField(syncErrors)
      if (!firstErrorField) return
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    submitOrderSucceeded: (response) => {
      setOrder(response)
      clearGiftCard()
      effects
        .updateCheckout({
          ...membershipForm,
          order: response.order,
        })
        .then((res) => {
          ReactGA.event('purchase', {
            currency: 'USD',
            transaction_id: response.transactionId,
            value: response.order.totalPaid,
            items: [
              ...(membershipForm.addOns?.map((item) => ({
                item_id: item.addOnId,
                item_name: item.displayName,
                item_category: 'membership',
                item_category2: item.addOnType,
                price: item.cost,
                quantity: item.quantity,
              })) || []),
              {
                item_id: selectedMembershipType.id,
                item_name: selectedMembershipType.displayName,
                item_category: 'membership',
                item_category2: 'membership',
                price: selectedMembershipType?.cost,
                quantity: 1,
              },
              membershipForm.donation?.amount && {
                item_name: 'donation',
                item_category: 'membership',
                item_category2: 'donation',
                price: membershipForm.donation.amount,
                quantity: 1,
              },
            ],
          })
        })

      goToConfirmation()
    },
  }
}

function onComponentDidMount({
  fetchAddOns,
  fetchFnboDiscounts,
  fetchMembershipTypes,
  fnboDiscounts,
  membershipForm: { sellerCode },
  membershipType,
  toggleSellerCodeActive,
}) {
  if (sellerCode.sellerCode) toggleSellerCodeActive()
  if (!fnboDiscounts) fetchFnboDiscounts()
  fetchMembershipTypes()
  fetchAddOns(membershipType.id)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  toggle(['sellerCodeActive', 'receiptFormActive']),
  modifyProps(modify),
  onMount(onComponentDidMount)
)(PaymentDetails)
