export default function mapStandardOrderData({
  formCustomerInfo,
  formBillingInfo = {},
  cartToken,
  mobileOptIn = false,
  partialPayment,
  deliveryOption,
}) {
  const orderDeliveryOption = deliveryOption ? { deliveryOption } : {}
  return {
    order: {
      customerInfo: {
        firstName: formCustomerInfo.firstName,
        lastName: formCustomerInfo.lastName,
        email: formCustomerInfo.email,
        phone: formCustomerInfo.phone,
        mobileOptIn,
      },
      billingInfo: {
        firstName: formBillingInfo.firstName,
        lastName: formBillingInfo.lastName,
        streetAddress1: formBillingInfo.streetAddress1,
        city: formBillingInfo.city,
        state: formBillingInfo.state,
        country: formBillingInfo.country,
        zip: formBillingInfo.zip,
        cardType: formBillingInfo.cardType,
        lastFourDigits: formBillingInfo.lastFourDigits,
        cardToken: formBillingInfo.cardToken,
        expirationDate: formBillingInfo.expirationDate,
      },
      ...orderDeliveryOption,
      partialPayment,
    },
    cartToken: cartToken,
  }
}
