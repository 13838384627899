import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import logo from 'images/nav-logo.png'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

function RemoveConfirmationModal({ isOpen, onClose, onConfirm, message }) {
  const handleOverlayClick = (event) => {
    if (!event.target.matches('[role="dialog"] *')) {
      onClose()
    }
  }

  return (
    isOpen &&
    ReactDOM.createPortal(
      <div
        aria-modal="true"
        className="absolute inset-0 flex items-center justify-center w-screen h-screen bg-gray-900 bg-opacity-50 sm:p-10 overscroll-contain"
        onClick={handleOverlayClick}
      >
        <div
          role="dialog"
          className="flex flex-col w-full h-full gap-5 p-10 text-xl bg-white md:w-1/2 sm:rounded-lg font-frutiger sm:h-auto overscroll-contain"
        >
          <img src={logo} className="w-1/2 mx-auto mb-4 sm:hidden" />
          <section className="space-y-10">
            <p className="text-xl font-medium text-center text-gray-900">
              {message}
            </p>
            <section className="flex flex-col items-center my-auto space-y-5">
              <button
                onClick={onConfirm}
                aria-label="Remove item"
                className="px-4 py-1 text-lg font-bold transition-colors border-none rounded-full cursor-pointer text-white-base bg-danger hover:bg-danger-600"
              >
                Yes, Permanently Remove
              </button>
              <button
                onClick={onClose}
                aria-label="Cancel"
                className="text-lg font-bold underline transition-colors bg-transparent border-none cursor-pointer hover:text-primary-700"
              >
                No, Cancel
              </button>
            </section>
          </section>
        </div>
      </div>,
      document.body
    )
  )
}

RemoveConfirmationModal.propTypes = propTypes

export default RemoveConfirmationModal
