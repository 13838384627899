import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as reduxForm from 'redux-form'
import * as routerActions from 'react-router-redux'
import * as effects from 'main/effects'
import * as actions from '../../actions'
import { PersonalInformationForm } from '../forms'
import { CartAccessor } from '../components'
import * as Sentry from '@sentry/react'
import CheckoutPageLayout from '../components/CheckoutPageLayout'
import { selectors } from '../../reducer'
import { mapCurrentUser, flashErrorMessage } from 'utils'
import * as apiActions from 'main/apiActions'

const propTypes = {
  push: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  setFoundationCart: PropTypes.func.isRequired,
  customerCountry: PropTypes.string,
  location: PropTypes.object.isRequired,
  accountInfo: PropTypes.object.isRequired,
  getAccountInfo: PropTypes.f,
}

const defaultProps = {}

function DonorDetails({
  push,
  flashErrorMessage,
  setFoundationCart,
  customerCountry,
  getAccountInfo,
  accountInfo,
  location: {
    query: { cartToken },
  },
}) {
  useEffect(() => {
    getAccountInfo()
  }, [])

  return (
    <CheckoutPageLayout step={0} cartToken={cartToken}>
      <h2>Donor Information</h2>
      <CartAccessor cartToken={cartToken}>
        {({ foundationCart }) => {
          const personalDetail =
            (foundationCart && foundationCart.foundationPersonalDetail) || {}

          const initialValues = {
            customerInfo: {
              address_1: personalDetail.address1,
              ...accountInfo,
              ...personalDetail,
              country: accountInfo?.country || 'US',
            },
          }

          function handleSubmit({ customerInfo }) {
            effects
              .setFoundationCartPersonalDetails({
                foundationCartToken: cartToken,
                cartAttrs: customerInfo,
              })
              .then((resp) => {
                setFoundationCart({ success: resp })

                const nextStep =
                  foundationCart.foundationPaymentDetail === null
                    ? 'payment'
                    : 'confirm'

                push(`/give/cart/${nextStep}?cartToken=${cartToken}`)
              })
              .catch((err) => {
                Sentry.captureException(err)
                const msg =
                  (err && err.errors && err.errors.message) ||
                  'Something went wrong. Please try again in a moment.'
                flashErrorMessage(msg, { style: 'foundation' })
              })
          }

          return (
            <PersonalInformationForm
              initialValues={initialValues}
              isUsCustomer={customerCountry === 'US'}
              onSubmit={handleSubmit}
            />
          )
        }}
      </CartAccessor>
    </CheckoutPageLayout>
  )
}

DonorDetails.propTypes = propTypes
DonorDetails.defaultProps = defaultProps
function mapStateToProps(state) {
  const getAccountInfo = (state) => {
    const accountInfo = selectors.foundationAccountInfo(state)

    if (accountInfo) {
      return mapCurrentUser(accountInfo)
    }
  }

  return {
    customerCountry: reduxForm.formValueSelector('foundation-cart-information')(
      state,
      'customerInfo.country'
    ),
    accountInfo: getAccountInfo(state),
  }
}

const mapDispatchToProps = {
  push: routerActions.push,
  flashErrorMessage,
  setFoundationCart: actions.setFoundationCart,
  getAccountInfo: apiActions.getAccountInfo,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DonorDetails
)
