import React, { useState } from 'react'
import { connect } from 'react-redux'
import c from 'classnames'
import ModalDialog from '../components/ui/ModalDialog'
import { selectors } from '../main/user/reducer'
import LinkButton from '../components/ui/LinkButton'
import ButtonLink from '../components/ui/ButtonLink'
import Anchor from '../components/ui/Anchor'
import { routerActions } from 'react-router-redux'
import Button from '../components/ui/Button'
import displayCurrency from '../utils/displayCurrency'
import { isNumber } from 'lodash'
import { RenderedHtml } from 'components'

const SocietyProductCard = ({
  id,
  featured,
  featuredLabel = 'Featured',
  imageUrl,
  description,
  fullDescription,
  productUrl,
  selectUrl,
  signInUrl,
  name,
  horizontal,
  push,
  isAuthenticated,
  isLoginRequired,
  startsExpanded,
  children,
  price,
  priceNote,
  onSelect,
  triggersNextStep = true,
  selected,
  selectLabel,
  membership,
}) => {

  const giftUrl = membership?.giftCardId ? `/gift-cards/details?id=${membership?.giftCardId}` : undefined;

  const selectable = selected !== undefined || !!selectUrl
  const expandable = Boolean(children)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(expandable && startsExpanded)

  const backgroundImage = `url(${imageUrl})`

  const userMustLogin = isLoginRequired && !isAuthenticated

  const selectText =
    selectLabel ||
    (selected ? 'Remove' : userMustLogin ? 'Sign In to Select' : 'Select')

  const ariaDescriptionId = `product-description-${id}`
  const ariaLabelForSelect = `${selectText} ${name}`
  const ariaLabelForToggle = `${isExpanded ? 'Open' : 'Close'} ${name}`
  const ariaLabelForLearnMore = `Learn more about ${name}`
  const ariaLabelForReadMore = `Read more about ${name}`
  const ariaLabelForGift = `Gift ${name} to a friend or loved one`

  const selectButton = selectable ? (
    <ButtonLink
      aria-describedby={ariaDescriptionId}
      aria-label={ariaLabelForSelect}
      label={selectText}
      href={triggersNextStep && (userMustLogin ? signInUrl : selectUrl)}
      primary
      lg
      onClick={onSelect}
      width="fixed"
    />
  ) : expandable ? (
    <Button
      aria-describedby={ariaDescriptionId}
      aria-label={ariaLabelForToggle}
      onClick={() => setIsExpanded(!isExpanded)}
      primary
      label={isExpanded ? 'Close' : 'Expand'}
      xl
    />
  ) : null

  const giftButton = giftUrl ? <ButtonLink
  aria-describedby={ariaDescriptionId}
  aria-label={ariaLabelForGift}
  label='Give as gift'
  href={giftUrl}
  primaryOutline
  lg
  width="fixed"
  className="mb-2"
/> : null

  return (
    <div
      className={c('w-full font-roboto', {
        shadow: isExpanded,
        'border-l-8 border-solid border-primary-500': selected,
      })}
    >
      <section
        className={c('flex flex-col relative w-full h-full bg-white', {
          'md:flex-row': horizontal,
          shadow: !isExpanded,
          'border-b border-solid border-gray-100': isExpanded,
        })}
      >
        <figure
          className={c(
            'relative h-72 flex items-end justify-center p-5 bg-cover bg-center',
            { 'md:h-auto': horizontal }
          )}
          style={{ backgroundImage }}
        >
          {featured && (
            <div className="absolute inset-x-0 top-0 px-2 text-lg font-bold text-center text-white uppercase truncate shadow-inner bg-tertiary-shade-400 bg-opacity-70">
              {featuredLabel}
            </div>
          )}
          <div className={c('hidden', { 'md:block': horizontal })}>
            {giftButton}
            {selectButton}
          </div>
        </figure>

        <section
          className={c('flex flex-col flex-1 gap-4 p-5 items-center', {
            'md:items-start': horizontal,
          })}
        >
          <header
            className={c('space-y-2 my-4 text-center', {
              'my-0 md:px-0 md:text-left': horizontal,
            })}
          >
            <h2 className="mb-0 text-4xl font-amatic">{name}</h2>
            {price && (
              <div className="font-bold">
                {isNumber(price) ? displayCurrency(price) : price}
                {priceNote && (
                  <div className="text-sm font-normal text-secondary">
                    {priceNote}
                  </div>
                )}
              </div>
            )}
          </header>
          <div className="w-full m-0" id={ariaDescriptionId}>
            <RenderedHtml>{description}</RenderedHtml>
          </div>
          <div className="flex gap-4">
            {fullDescription && (
              <LinkButton
                aria-describedby={ariaDescriptionId}
                aria-label={ariaLabelForReadMore}
                label="Read More"
                onClick={() => setIsModalOpen(true)}
                primary
              />
            )}
            {productUrl && (
              <Anchor
                aria-label={ariaLabelForLearnMore}
                aria-describedby={ariaDescriptionId}
                href={productUrl}
                label="Learn More"
                primary
              />
            )}
          </div>
        </section>
        <footer
          className={c('p-5 self-center justify-self-end', {
            'md:hidden': horizontal,
          })}
        >
          {giftButton}
          {selectButton}
        </footer>
      </section>
      {isExpanded && (
        <section className="p-5 pt-16 -mt-5 bg-white">{children}</section>
      )}

      <ModalDialog
        isOpen={isModalOpen}
        title={name}
        content={<RenderedHtml>{fullDescription}</RenderedHtml>}
        confirmLabel={selectText}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => !userMustLogin && push(selectUrl)}
        onSecondaryConfirm={giftUrl}
        secondaryConfirmLabel={giftUrl? 'Give As Gift' : undefined}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return { isAuthenticated: selectors.isAuthenticated(state) }
}

const mapDispatchToProps = {
  push: routerActions.push,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocietyProductCard)
