import React from 'react'
import SocietyPageLayout from '../../../layout/SocietyPageLayout'
import Anchor from '../../../components/ui/Anchor'
import { Link } from 'react-router'
import OrderHistory from './shared/OrderHistory'

function SocietyOrderHistory() {
  return (
    <SocietyPageLayout
      title="Account Information"
      introduction={
        <Anchor as={Link} to="/my-account" label="My Account" primary lg />
      }
      className="p-5 bg-white shadow"
    >
      <OrderHistory />
    </SocietyPageLayout>
  )
}


export default SocietyOrderHistory
