import PropTypes from 'prop-types'

export const MembershipTypes = {
  DUAL: 'dual',
  GRANDPARENT: 'grandparent',
  HOUSEHOLD: 'household',
  INDIVIDUAL: 'individual',
  PATRON: 'patron',
  MILITARY: 'military',
}

export const TicketActivities = {
  ZOO: 'Zoo Admission',
  SPECIAL_EVENT: 'Special Events',
  RIDES: 'Zoo Rides',
  THEATER: 'Theater',
}

export const TicketAgeGroups = {
  ADULT: 'adult',
  CHILD: 'child',
  SENIOR: 'senior',
}

export const UdfFieldTypes = {
  NUMERIC: 'numeric',
  STRING: 'string',
  BOOLEAN: 'checkbox',
  SELECT: 'dropdown',
  DATE: 'date',
  TEXT: 'text',
  RADIO: 'radio',
}

export const IdMeErrorCode = {
  ACCESS_DENIED: 'access_denied',
}

export const childType = TicketAgeGroups.CHILD
export const adultType = TicketAgeGroups.ADULT
export const seniorType = TicketAgeGroups.SENIOR

export const TicketFormStepTitles = {
  0: 'Select Ticket',
  1: 'Ticket Details',
  2: 'Select Add-Ons',
  3: 'Shopping Cart',
  4: 'Payment Info',
}

export const TicketFormStepRoutes = {
  0: '/ticketing',
  1: '/ticketing/details',
  2: '/ticketing/add-ons',
  3: '/ticketing/cart',
  4: '/ticketing/pay',
}

export const TICKETING_CHECKOUT_STEPS = [
  { name: 'Select Ticket', url: '/ticketing' },
  { name: 'Ticket Details', url: '/ticketing/details' },
  { name: 'Select Add-Ons', url: '/ticketing/add-ons' },
  { name: 'Shopping Cart', url: '/ticketing/cart' },
  { name: 'Payment Info', url: '/ticketing/pay' },
]

export const MEMBERSHIP_CHECKOUT_STEPS = [
  { name: 'Select Membership', url: '/membership' },
  { name: 'Membership Details', url: '/membership/details' },
  { name: 'Select Add-Ons', url: '/membership/add-ons' },
  { name: 'Payment Info', url: '/membership/pay' },
]

export const DONATION_CHECKOUT_STEPS = [
  { name: 'Select Fund', url: '/donation' },
  { name: 'Donation Details', url: '/donation/donate' },
]

export const GIFT_CARD_CHECKOUT_STEPS = [
  { name: 'Select Gift Card', url: '/gift-cards' },
  { name: 'Gift Card Details', url: '/gift-cards/donate' },
]

export const MemberFormStepTitles = {
  0: 'Select Membership',
  1: 'Membership Details',
  2: 'Select Add-Ons',
  3: 'Payment Info',
}

export const MemberFormStepRoutes = {
  0: '/membership',
  1: '/membership/details',
  2: '/membership/add-ons',
  3: '/membership/pay',
}

export const customerServiceNumber = '402-733-8401'

export const DonationFormStepTitles = {
  0: 'Select Fund',
  1: 'Donation Details',
}

export const DonationFormStepRoutes = {
  0: '/donation',
  1: '/donation/donate',
}

export const GiftCardFormStepTitles = {
  0: 'Select Gift Card',
  1: 'Gift Card Details',
}

export const GiftCardFormStepRoutes = {
  0: '/gift-cards',
  1: '/gift-cards/details',
}

export const MyAccountOptionTitles = {
  1: 'Giving',
  3: 'Account Information',
}

export const addOn = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  membershipClass: PropTypes.string,
  cost: PropTypes.number.isRequired,
  minimumAgeRequired: PropTypes.number,
  maximumAgeRequirement: PropTypes.number,
  isMembershipAgeBased: PropTypes.bool,
  salePrice: PropTypes.number,
  isPriceTaxInclusive: PropTypes.bool,
  taxPercentage: PropTypes.number,
  packageOnly: PropTypes.bool,
})

export const address = PropTypes.shape({
  streetAddress: PropTypes.string,
  streetAddressTwo: PropTypes.string,
  suburb: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  workPhone: PropTypes.string,
  mobilePhone: PropTypes.string,
})

export const member = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  title: PropTypes.string,
  email: PropTypes.string.isRequired,
  address: address,
  isPrimary: PropTypes.bool.isRequired,
  primaryMemberId: PropTypes.number.isRequired,
  memberCode: PropTypes.number.isRequired,
  memberNumber: PropTypes.number.isRequired,
  customerId: PropTypes.number, // it can be empty for existing members
  externalId: PropTypes.number, // it can be empty for existing members
})

export const secondaryMember = PropTypes.shape({
  isSecondary: PropTypes.bool.isRequired,
  fullName: PropTypes.string,
})

export const selectedSecondaryMember = PropTypes.shape({
  id: PropTypes.number,
  isSecondary: PropTypes.bool.isRequired,
})

export const membershipType = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  membershipClass: PropTypes.string,
  centamanId: PropTypes.number.isRequired,
})

export const order = PropTypes.shape({
  orderNumber: PropTypes.string.isRequired,
  membershipId: PropTypes.number.isRequired,
  renewal: PropTypes.bool.isRequired,
  totalPaid: PropTypes.number.isRequired,
  membershipTypeId: PropTypes.number.isRequired,
  membershipName: PropTypes.string.isRequired,
  primaryMemberId: PropTypes.number.isRequired,
  primaryMemberNumber: PropTypes.number.isRequired,
  buyerName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  coupon: PropTypes.string,
  cardType: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  purchaseDate: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  membershipStarts: PropTypes.string.isRequired,
  membershipEnds: PropTypes.string.isRequired,
  validDates: PropTypes.string.isRequired,
})

export const lineItem = PropTypes.shape({
  description: PropTypes.string.isRequired,
  dateDescription: PropTypes.string,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
})

export const orderData = PropTypes.shape({
  customerEmail: PropTypes.string.isRequired,
  customerId: PropTypes.number.isRequired,
  customerName: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(lineItem),
  paymentStatus: PropTypes.string.isRequired,
  receipt: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  transactionDate: PropTypes.string.isRequired,
  mailingFee: PropTypes.number.isRequired,
})

export const udf = PropTypes.shape({
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  fieldLength: PropTypes.number.isRequired,
  tabName: PropTypes.string,
  inputType: PropTypes.oneOf(Object.values(UdfFieldTypes)),
  categoryType: PropTypes.string.isRequired,
})

export const tag = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const ticketCategory = PropTypes.shape({
  categoryId: PropTypes.number.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryImage: PropTypes.string.isRequired,
  categoryDescription: PropTypes.string,
  categoryFeatured: PropTypes.bool,
  categoryFeaturedDisplayLabel: PropTypes.string,
  categoryIsDirectLink: PropTypes.bool,
  categoryLearnMoreUrl: PropTypes.string,
})
// request activity times on the activity nested route
// then request the specific activity time for the price array
// prices will always be an empty array on the activity time when in the context of the index route
export const ticketActivity = PropTypes.shape({
  id: PropTypes.number.isRequired,
  categoryName: PropTypes.string,
  categoryDescription: PropTypes.string,
  categoryFeatured: PropTypes.bool,
  categoryFeaturedDisplayLabel: PropTypes.string,
  categoryIsDirectLink: PropTypes.bool,
  categoryLearnMoreUrl: PropTypes.string,
  categoryImage: PropTypes.string,
  categoryId: PropTypes.number,
  type: PropTypes.string,
  productType: PropTypes.string,
  displayName: PropTypes.string,
  centamanId: PropTypes.number,
  activityTimeDate: PropTypes.string,
  enableUpgrade: PropTypes.bool,
  allDay: PropTypes.bool,
  active: PropTypes.bool,
  display: PropTypes.bool,
  featured: PropTypes.bool,
  featuredDisplayLabel: PropTypes.string,
  activityImage: PropTypes.string,
  caption: PropTypes.string,
  collectAttendeeDetails: PropTypes.bool,
  eventInformation: PropTypes.string,
  ticketRestrictions: PropTypes.string,
  ticketDetails: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  infoLink: PropTypes.string,
  infoModalText: PropTypes.string,
  enableDonation: PropTypes.bool,
  donationHeader: PropTypes.string,
  donationText: PropTypes.string,
  donationImage: PropTypes.string,
  limitTicketCapacity: PropTypes.bool,
  restrictAddOnStartTime: PropTypes.bool,
  showTicketsRemaining: PropTypes.bool,
  showTicketsLeftThresholdGreen: PropTypes.number,
  showTicketsLeftThresholdYellow: PropTypes.number,
  showTicketsLeftThresholdRed: PropTypes.number,
  enableMilitaryDiscount: PropTypes.bool,
  maxTickets: PropTypes.number,
  position: PropTypes.number,
  bookingUdfs: PropTypes.arrayOf(udf),
  activityUdfs: PropTypes.arrayOf(udf),
  requireLogin: PropTypes.bool,
  largeGroup: PropTypes.bool,
  fieldTrip: PropTypes.bool,
  tags: PropTypes.arrayOf(tag).isRequired,
})

export const ticketPrice = PropTypes.shape({
  id: PropTypes.number.isRequired,
  centamanId: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.number.isRequired,
})

export const ticketActivityTime = PropTypes.shape({
  id: PropTypes.number.isRequired,
  activityId: PropTypes.number.isRequired,
  centamanId: PropTypes.number.isRequired,
  prices: PropTypes.arrayOf(ticketPrice),
  displayName: PropTypes.string.isRequired,
  startDatetime: PropTypes.string.isRequired,
  endDatetime: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  displayStartTime: PropTypes.string.isRequired,
  displayEndTime: PropTypes.string.isRequired,
  displayTime: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
  vacancy: PropTypes.number.isRequired,
  soldOut: PropTypes.bool.isRequired,
})

export const ticketLineItemPrice = PropTypes.shape({
  id: PropTypes.number,
  ticketingLineItemId: PropTypes.number,
  priceId: PropTypes.number,
  quantity: PropTypes.number,
  price: PropTypes.number,
  total: PropTypes.number,
  subtotal: PropTypes.number,
  taxes: PropTypes.number,
  discounts: PropTypes.number,
  costRateId: PropTypes.number,
  coupon: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  displayName: PropTypes.string,
})

// eventId only exists for timed Activities and corresponds
// to the primary key and centamenId on the ticketActivityTime
let localTicketLineItem = PropTypes.shape({
  id: PropTypes.number.isRequired,
  ticketingCartId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  productType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  parentLineItemId: PropTypes.number,
  eventId: PropTypes.number,
  eventName: PropTypes.string,
  lineItemPrices: PropTypes.arrayOf(ticketLineItemPrice),
  displayEndTime: PropTypes.string,
  displayStartTime: PropTypes.string,
  displayTime: PropTypes.string,
  endDate: PropTypes.string,
  endDatetime: PropTypes.string,
  endTime: PropTypes.string,
  startDate: PropTypes.string,
  startDatetime: PropTypes.string,
  startTime: PropTypes.string,
  productCategoryId: PropTypes.number,
  waiverUrl: PropTypes.string,
})

// self-referential type needs to be set after initial definition
localTicketLineItem.addOnLineItems = PropTypes.arrayOf(localTicketLineItem)

export const ticketLineItem = PropTypes.shape({ ...localTicketLineItem })

export const ticketCart = PropTypes.shape({
  id: PropTypes.number.isRequired,
  costRateId: PropTypes.number,
  subtotal: PropTypes.number,
  taxes: PropTypes.number,
  discounts: PropTypes.number,
  totalGiftCard: PropTypes.number,
  total: PropTypes.number,
  giftCardNumber: PropTypes.string,
  giftCardBalance: PropTypes.number,
  coupon: PropTypes.string,
  donationAmount: PropTypes.number,
  token: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(ticketLineItem),
  schoolId: PropTypes.number,
  schoolName: PropTypes.string,
})

export const ticketAddOn = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  caption: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  infoModalText: PropTypes.string,
  mainPath: PropTypes.bool,
})

export const ticketAddOnCategory = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  featured: PropTypes.bool.isRequired,
  featuredDisplayLabel: PropTypes.string,
  isDirectLink: PropTypes.bool.isRequired,
  categoryImage: PropTypes.string,
  products: PropTypes.arrayOf(ticketAddOn),
})

export const ticketOrder = PropTypes.shape({
  id: PropTypes.number.isRequired,
  analyticsDetails: PropTypes.object,
  attachmentLink: PropTypes.string.isRequired,
  billingName: PropTypes.string,
  cart: ticketCart,
  customerName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  purchaseDate: PropTypes.string.isRequired,
  giftCardBalance: PropTypes.number,
  giftCardNumber: PropTypes.string,
  paymentMethod: PropTypes.string,
  receipt: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  totalGiftCard: PropTypes.number,
  totalPaid: PropTypes.number,
})

export const school = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const attendee = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
})

export const donationLevel = PropTypes.shape({
  id: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  amount: PropTypes.number,
  customAmount: PropTypes.bool.isRequired,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,
})

export const selectedDonationLevel = donationLevel

export const donationFund = PropTypes.shape({
  id: PropTypes.number.isRequired,
  featured: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  donationLevels: PropTypes.arrayOf(donationLevel).isRequired,
  donationHeader: PropTypes.string,
  donationText: PropTypes.string,
  image: PropTypes.string,
  caption: PropTypes.string,
  featuredDisplayLabel: PropTypes.string,
  priceNote: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  infoModalText: PropTypes.string,
})

export const donationOrder = PropTypes.shape({
  email: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  totalPaid: PropTypes.number.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  purchaseDate: PropTypes.string.isRequired,
  buyerName: PropTypes.string.isRequired,
  displayDate: PropTypes.string.isRequired,
  donationFundId: PropTypes.number.isRequired,
})

export const giftCard = PropTypes.shape({
  id: PropTypes.number.isRequired,
  featured: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  caption: PropTypes.string,
  image: PropTypes.string,
  featuredDisplayLabel: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  infoModalText: PropTypes.string,
  isFixedPrice: PropTypes.bool.isRequired,
  maxAmount: PropTypes.number.isRequired,
  minAmount: PropTypes.number.isRequired,
  price: PropTypes.number,
  position: PropTypes.number.isRequired,
})

// When diana updates staging add the type column too priceSelection

export const priceSelection = PropTypes.shape({
  id: PropTypes.number,
  centamanId: PropTypes.number,
  displayName: PropTypes.string,
  price: PropTypes.number,
  fixedPrice: PropTypes.number,
  variablePrice: PropTypes.number,
  quantity: PropTypes.number,
  subtotal: PropTypes.number,
  total: PropTypes.number,
  variableTotal: PropTypes.number,
})

export const giftCardCartProduct = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  displayName: PropTypes.string,
  priceSelecions: PropTypes.arrayOf(priceSelection),
})

export const giftCardCart = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  purchased: PropTypes.bool,
  customerId: PropTypes.number,
  costRateId: PropTypes.number,
  total: PropTypes.number,
  subtotal: PropTypes.number,
  taxes: PropTypes.number,
  discounts: PropTypes.number,
  products: PropTypes.arrayOf(giftCardCartProduct),
})

export const giftCardOrder = PropTypes.shape({
  id: PropTypes.number,
  token: PropTypes.string,
  receipt: PropTypes.number,
  orderNumber: PropTypes.number,
  transactionId: PropTypes.number,
  purchaseDate: PropTypes.string,
  buyerName: PropTypes.string,
  purchaseTotal: PropTypes.number,
  totalPaid: PropTypes.number,
  paymentMethod: PropTypes.string,
  email: PropTypes.string,
  attachmentLink: PropTypes.string,
})

// FOUNDATION
export const foundationProduct = PropTypes.shape({
  id: PropTypes.number,
  sfid: PropTypes.string,
  foundationProductCategoryId: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  amount: PropTypes.number,
  position: PropTypes.number,
  active: PropTypes.bool,
  inscriptionLimitLine1: PropTypes.number,
  inscriptionLimitLine2: PropTypes.number,
  inscriptionLimitLine3: PropTypes.number,
  inscriptionLimitLine4: PropTypes.number,
  inscriptionLimitLine5: PropTypes.number,
  inventoryLimitDisplay: PropTypes.number,
  inventoryLimitReal: PropTypes.number,
  currentInventoryBought: PropTypes.number,
  displayLimitInApp: PropTypes.bool,
})

export const foundationProductCategory = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  donationType: PropTypes.string.isRequired,
  largeImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  featuredImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  homepageDescription: PropTypes.string,
  showpageTitle: PropTypes.string,
  showpageDescription: PropTypes.string,
  position: PropTypes.number,
  recurringEnabled: PropTypes.bool,
  animalStatus: PropTypes.string,
  animalZooLocation: PropTypes.string,
  featured: PropTypes.bool,
  featuredText: PropTypes.string,
  foundationProducts: PropTypes.arrayOf(foundationProduct),
})

export const foundationLineItem = PropTypes.shape({
  id: PropTypes.number.isRequired,
  foundationCartId: PropTypes.number.isRequired,
  foundationProductId: PropTypes.string,
  foundationProductCategoryId: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  valueGoodsAndServices: PropTypes.number.isRequired,
  position: PropTypes.number,
  inMemoryOfName: PropTypes.string,
  memorialGiving: PropTypes.bool,
  tributeType: PropTypes.string,
  packageDesignation: PropTypes.string,
  mailToDesignation: PropTypes.string,
  adoptAnAnimalDonationType: PropTypes.string,
  packageDesignatedTo: PropTypes.string,
  packageDesignatedFrom: PropTypes.string,
  recipientFirstName: PropTypes.string,
  recipientLastName: PropTypes.string,
  recipientAddress1: PropTypes.string,
  recipientAddress2: PropTypes.string,
  recipientCity: PropTypes.string,
  recipientState: PropTypes.string,
  recipientZip: PropTypes.string,
  recipientCountry: PropTypes.string,
  donationType: PropTypes.string,
  inscriptionMessageLine1: PropTypes.string,
  inscriptionMessageLine2: PropTypes.string,
  inscriptionMessageLine3: PropTypes.string,
  inscriptionMessageLine4: PropTypes.string,
  inscriptionMessageLine5: PropTypes.string,
  valid: PropTypes.bool,
})

const foundationCartPersonalDetail = PropTypes.shape({
  id: PropTypes.number,
  foundationCartId: PropTypes.number,
  address: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
})

const foundationCartPaymentDetail = PropTypes.shape({
  id: PropTypes.number,
  foundationCartId: PropTypes.number,
  cardToken: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  streetAddress1: PropTypes.string,
  streetAddress2: PropTypes.string,
  zip: PropTypes.string,
})

export const foundationInscriptionLineLimits = PropTypes.shape({
  id: PropTypes.number.isRequired,
  foundationProductId: PropTypes.number.isRequired,
  linePosition: PropTypes.number.isRequired,
  characterLimit: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
})

export const foundationCart = PropTypes.shape({
  id: PropTypes.number,
  token: PropTypes.string,
  status: PropTypes.string,
  total: PropTypes.number,
  totalValueGoodsAndServices: PropTypes.number,
  foundationPersonalDetail: foundationCartPersonalDetail,
  foundationPaymentDetail: foundationCartPaymentDetail,
  foundationLineItems: PropTypes.arrayOf(foundationLineItem),
})
