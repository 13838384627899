import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, propTypes as formPropTypes, SubmissionError, change, formValueSelector } from 'redux-form'
import { scroller } from 'react-scroll'
import { RecaptchaInput, GiftCardSection, HideableArea } from 'components'
import { lpForm } from '@launchpadlab/lp-form'
import { Input, Select } from '@launchpadlab/lp-components'
import { modifyProps } from '@launchpadlab/lp-hoc'
import * as options from 'main/options'
import * as effects from 'main/effects'
import {
  first,
  keys,
  getCountryOptions,
  setZipConstraints,
  validateRecaptcha,
} from 'utils'
import { GpiForm } from 'components/Gpi'
import * as Sentry from '@sentry/react'

import {
  PRIMARY_MEMBER_FIRST_NAME_MAX_LENGTH,
  PRIMARY_MEMBER_LAST_NAME_MAX_LENGTH,
} from 'config'
import SocietyHeading from '../../../layout/SocietyHeading'
import CovidTermsCheckbox from '../../../components/CovidTermsCheckbox'
import MembershipTermsCheckbox from '../../../components/MembershipTermsCheckbox'

const membershipPaymentForm = 'membership-payment'

const propTypes = {
  ...formPropTypes,
  primaryCountry: PropTypes.string,
  skipBillingInfo: PropTypes.bool.isRequired,
  appliedGiftCardAmount: PropTypes.number,
  remainingGiftCardBalance: PropTypes.number,
  onGiftCardApply: PropTypes.func.isRequired,
  onGiftCardApplySuccess: PropTypes.func.isRequired,
  onGiftCardApplyFail: PropTypes.func.isRequired,
  onGiftCardRemove: PropTypes.func.isRequired,
  onGiftCardRemoveSuccess: PropTypes.func.isRequired,
  onGiftCardRemoveFail: PropTypes.func.isRequired,
  changeForm: PropTypes.func,
}

const defaultProps = {
  appliedGiftCardAmount: 0,
  remainingGiftCardBalance: null,
}

function PaymentForm({
  form,
  handleSubmit,
  primaryCountry,
  skipBillingInfo,
  appliedGiftCardAmount,
  remainingGiftCardBalance,
  onGiftCardApply,
  onGiftCardApplySuccess,
  onGiftCardApplyFail,
  onGiftCardRemove,
  onGiftCardRemoveSuccess,
  onGiftCardRemoveFail,
  changeForm,
  primaryMember,
}) {
  const [useSameAddressCheckbox, setUseSameAddressCheckbox] = useState(true)

  useEffect(() => {
    if (useSameAddressCheckbox) {
      changeForm(membershipPaymentForm,'nameOnCard', `${primaryMember?.firstName} ${primaryMember?.lastName}`)
      changeForm(membershipPaymentForm,'address', primaryMember?.address?.streetAddress)
      changeForm(membershipPaymentForm,'zip', primaryMember?.address?.zip)
    }
  }, [changeForm, useSameAddressCheckbox, primaryMember?.firstName, primaryMember?.lastName, primaryMember?.address?.streetAddress, primaryMember?.address?.zip])
    
  function handleUseSameAddress() {
    setUseSameAddressCheckbox(!useSameAddressCheckbox)
  }

  const isUsPrimary = primaryCountry === 'US'
  return (
    <form onSubmit={handleSubmit} id={form} noValidate className="space-y-5">
      <div className="p-5 bg-white shadow">
        <SocietyHeading>Primary Member Details</SocietyHeading>
        <div className="flex gap-5">
          <Field name="primaryMember.firstName" component={Input} />
          <Field name="primaryMember.lastName" component={Input} />
        </div>
        <Field
          name="primaryMember.phone"
          component={Input}
          label="Phone Number"
        />
        <Field
          name="primaryMember.address.country"
          component={Select}
          options={getCountryOptions()}
          label="Country"
          placeholder="Select"
        />
        <Field
          name="primaryMember.address.streetAddress"
          component={Input}
          label="Street Address"
        />
        <Field
          name="primaryMember.address.suburb"
          component={Input}
          label="City"
        />
        <div className="flex gap-5">
          {isUsPrimary ? (
            <Field
              name="primaryMember.address.state"
              component={Select}
              options={options.states}
              label={'State'}
              placeholder={'Select'}
            />
          ) : (
            <Field
              name="primaryMember.address.state"
              component={Input}
              label="State / Province"
            />
          )}
          <Field
            name="primaryMember.address.zip"
            component={Input}
            label="Zip / Post Code"
          />
        </div>
      </div>

      <div className="p-5 bg-white shadow">
        <HideableArea hidden={skipBillingInfo}>
          <SocietyHeading>Billing Details</SocietyHeading>
        </HideableArea>
        <span className="checkbox">
            <input
              id="copy-to-billing-checkbox"
              type="checkbox"
              onChange={handleUseSameAddress}
              checked={useSameAddressCheckbox}
            />
            <label htmlFor="copy-to-billing-checkbox">Use the same address as member details</label>
          </span>
        <GiftCardSection
          acceptGiftCard={!skipBillingInfo}
          appliedAmount={appliedGiftCardAmount}
          remainingBalance={remainingGiftCardBalance}
          onApply={onGiftCardApply}
          onApplySuccess={onGiftCardApplySuccess}
          onApplyFail={onGiftCardApplyFail}
          onRemove={onGiftCardRemove}
          onRemoveSuccess={onGiftCardRemoveSuccess}
          onRemoveFail={onGiftCardRemoveFail}
        />

        {
          // The normal way to hide or not-hide components would be to
          // render or not render based on skipBillingInfo
          // However the GpiForm uses a third party script
          // which gets thrown off when we were rendering and not-rendering.
          // So the solution was to use this HideableArea component which
          // just adds a display: none when skipBillingInfo: true
        }
        <HideableArea hidden={skipBillingInfo}>
          <Field name="nameOnCard" component={Input} />
          <GpiForm />
          <Field name="address" component={Input} label="Street Address" />
          <Field name="zip" component={Input} label="Zip / Post Code" />
        </HideableArea>

        <Field
          className="m-0"
          aria-label="terms and conditions"
          name="termsAndConditions"
          component={({ input: { value, onChange }, meta }) => (
            <MembershipTermsCheckbox
              checked={value}
              onChange={onChange}
              error={
                (!meta.pristine || meta.submitFailed) &&
                meta.error &&
                meta.error[0]
              }
            />
          )}
          label={false}
          required
        />
        <Field
          className="m-0"
          aria-label="covid-19 terms and conditions"
          name="covidTermsAndConditions"
          component={({ input: { value, onChange }, meta }) => (
            <CovidTermsCheckbox
              checked={value}
              onChange={onChange}
              productType="membership"
              error={
                (!meta.pristine || meta.submitFailed) &&
                meta.error &&
                meta.error[0]
              }
            />
          )}
          label={false}
          required
        />

        <div className="mt-5">
          <Field
            name="recaptcha"
            label={false}
            component={RecaptchaInput}
            validate={validateRecaptcha}
          />
        </div>
      </div>
    </form>
  )
}

PaymentForm.propTypes = propTypes
PaymentForm.defaultProps = defaultProps

function modify({ onSubmit, primaryCountry, skipBillingInfo }) {
  return {
    onSubmit: (values, dispatch, props) => {
      return effects
        .verifyReCaptcha(values.recaptcha)
        .then(() => onSubmit(values, dispatch, props))
        .catch((err) => {
          Sentry.captureException(err)
          throw new SubmissionError({
            _error: err.response.errors.message,
          })
        })
    },
    constraints: {
      'primaryMember.firstName': {
        presence: true,
        length: {
          maximum: PRIMARY_MEMBER_FIRST_NAME_MAX_LENGTH,
        },
      },
      'primaryMember.lastName': {
        presence: true,
        length: {
          maximum: PRIMARY_MEMBER_LAST_NAME_MAX_LENGTH,
        },
      },
      'primaryMember.phone': {
        presence: true,
        length: { maximum: 20 },
      },
      'primaryMember.address.streetAddress': {
        presence: true,
        length: { maximum: 50 },
      },
      'primaryMember.address.suburb': {
        presence: true,
        length: { maximum: 30 },
      },
      'primaryMember.address.state': {
        presence: true,
      },
      'primaryMember.address.zip': setZipConstraints(primaryCountry),
      'primaryMember.address.country': {
        presence: true,
      },
      nameOnCard: () => {
        if (skipBillingInfo) return null
        return { presence: true }
      },
      address: () => {
        if (skipBillingInfo) return null
        return {
          presence: true,
          length: { maximum: 50 },
        }
      },
      zip: () => {
        if (skipBillingInfo) return null
        return {
          presence: true,
        }
      },
      termsAndConditions: {
        presence: {
          message: '^You must accept the terms and conditions',
        },
        inclusion: {
          within: [true],
          message: '^You must accept the terms and conditions',
        },
      },
      covidTermsAndConditions: {
        presence: {
          message:
            '^You must read and accept the COVID-19 terms and conditions',
        },
        inclusion: {
          within: [true],
          message:
            '^You must read and accept the COVID-19 terms and conditions',
        },
      },
    },
  }
}

const formSelector = formValueSelector(membershipPaymentForm)


function mapStateToProps(state) {
  return {
    primaryMember: formSelector(state, 'primaryMember'),
}
}

const mapDispatchToProps = {
  changeForm: change,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  lpForm({
    name: membershipPaymentForm,
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, { smooth: true })
    },
    enableReinitialize: true,
  })
)(PaymentForm)
