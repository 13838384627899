import React from 'react'
import { connect } from 'react-redux'
import { mapCurrentUser } from 'utils'
import { selectors } from '../../reducer'
import { Link } from 'react-router'

import AccountIcon from 'images/ozf-nav-account.svg'

function AccountNav({ isAuthenticated, accountInfo }) {
  const { firstName, lastName, email } = accountInfo || {}

  const avatar =
    firstName && lastName ? (
      firstName.charAt(0) + lastName.charAt(0)
    ) : (
      <img src={AccountIcon} />
    )

  return (
    <div className="account-nav">
      {isAuthenticated ? (
        <Link
          key="avatar"
          to="/give/my-account"
          className="avatar"
          title={`Logged in as ${email}`}
        >
          {avatar}
        </Link>
      ) : (
        <Link key="sign-in-link" to="/give/sign-in" className="nav-bar__link">
          Sign In
        </Link>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  const getAccountInfo = (state) => {
    const accountInfo = selectors.foundationAccountInfo(state)

    if (accountInfo) {
      return mapCurrentUser(accountInfo)
    }
  }
  return {
    accountInfo: getAccountInfo(state),
    isAuthenticated: selectors.isAuthenticated(state),
  }
}

export default connect(mapStateToProps)(AccountNav)
