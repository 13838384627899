import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { modifyProps } from '@launchpadlab/lp-hoc'
import * as routerActions from 'react-router-redux'
import * as effects from 'main/effects'
import * as actions from '../../actions'
import { displaySubmitFailure, findFirstErrorField, isEmpty } from 'utils'
import { scroller } from 'react-scroll'
import { PaymentForm } from '../forms'
import CheckoutPageLayout from '../components/CheckoutPageLayout'
import { selectors } from '../../reducer'
import { set } from 'lodash'
import { CartAccessor } from '../components'

const propTypes = {
  submitPaymentDetails: PropTypes.func.isRequired,
  submitPaymentSucceeded: PropTypes.func.isRequired,
  submitPaymentFailed: PropTypes.func.isRequired,
  setFoundationCart: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  location: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
}

const defaultProps = {}

function PaymentDetails({
  push,
  submitPaymentDetails,
  submitPaymentSucceeded,
  submitPaymentFailed,
  location: {
    query: { cartToken },
  },
  foundationCart,
}) {
  function handleSubmit(response) {
    submitPaymentDetails(cartToken, response)

    push(`/give/cart/confirm?cartToken=${cartToken}`)
    return set(foundationCart, 'foundationPaymentDetail', response.billingInfo)
  }

  const contactInfo = foundationCart
    ? foundationCart.foundationPersonalDetail
    : {}

  const billingInfo = {
    city: contactInfo.city,
    state: contactInfo.state,
    address1: contactInfo.address1,
    zip: contactInfo.zip,
  }

  return (
    <CheckoutPageLayout
      step={1}
      cartToken={cartToken}
      backUrl={`/give/cart/info?cartToken=${cartToken}`}
    >
      <CartAccessor />
      <h2>Payment Method</h2>

      <PaymentForm
        onSubmit={handleSubmit}
        onSubmitSuccess={submitPaymentSucceeded}
        onSubmitFail={submitPaymentFailed}
        initialValues={{ billingInfo }}
      />
    </CheckoutPageLayout>
  )
}

PaymentDetails.propTypes = propTypes
PaymentDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return { foundationCart: selectors.foundationCart(state) }
}

const mapDispatchToProps = {
  setFoundationCart: actions.setFoundationCart,
  push: routerActions.push,
}

function modify({ setFoundationCart }) {
  return {
    submitPaymentDetails: (foundationCartToken, formResponse) => {
      return new Promise((resolve, reject) => {
        const formData = formResponse.billingInfo
        effects
          .setFoundationCartPaymentDetails({
            foundationCartToken,
            cartAttrs: {
              cardToken: formData.cardToken,
              expirationDate: formData.expirationDate,
              lastFourDigits: formData.lastFourDigits,
              firstName: formData.firstName,
              lastName: formData.lastName,
              address_1: formData.address1,
              address_2: formData.address2,
              // needs to be formatted as snake case in the form to align with API contract
              city: formData.city,
              state: formData.state,
              zip: formData.zip,
              recaptcha: formResponse.recaptcha,
            },
          })
          .then(resolve)
          .catch(reject)
      })
    },
    submitPaymentSucceeded: (cart) => {
      setFoundationCart({
        success: { data: { additionalData: { cart } } },
      })
    },
    submitPaymentFailed: (errors, dispatch, submitError, { syncErrors }) => {
      errors['foundationPaymentMessage'] = true
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return
        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify)
)(PaymentDetails)
