import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { FoundationInput } from 'components'
import { SubmitButton } from '@launchpadlab/lp-components'
import { scroller } from 'react-scroll'
import { lpForm } from '@launchpadlab/lp-form'
import { modifyProps } from '@launchpadlab/lp-hoc'
import { first, keys, getCountryOptions } from 'utils'
import * as options from 'main/options'
import { selectors } from '../../reducer'
import { connect } from 'react-redux'
import { Field } from 'redux-form'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUsCustomer: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}

function PersonalInformationForm({
  handleSubmit,
  invalid,
  submitting,
  initialValues,
  isUsCustomer,
  isAuthenticated,
}) {
  // need to check first name because Country Code is always passed in as initial values
  const editing =
    initialValues &&
    initialValues.customerInfo &&
    initialValues.customerInfo.firstName
  return (
    <form onSubmit={handleSubmit}>
      <div className="member-form">
        <div className="flex gap-5">
          <Field
            name="customerInfo.firstName"
            component={FoundationInput}
            label="First Name"
            placeholder="First Name"
          />

          <Field
            name="customerInfo.lastName"
            component={FoundationInput}
            label="Last Name"
            placeholder="Last Name"
          />
        </div>
        <Field
          name="customerInfo.email"
          component={FoundationInput}
          label="Email"
          placeholder="Email"
          disabled={isAuthenticated}
        />
        <Field
          name="customerInfo.phone"
          component={FoundationInput}
          label="Phone"
          placeholder="Phone"
        />
        <Field
          name="customerInfo.country"
          component={FoundationInput}
          options={getCountryOptions()}
          label="Country"
          inputType="select"
          placeholder="Select"
        />
        <Field
          name="customerInfo.address_1" // needs to be formatted as snake case to align with API contract
          component={FoundationInput}
          label="Street Address"
          placeholder="Street Address"
        />
        <Field
          name="customerInfo.city"
          component={FoundationInput}
          label="City"
          placeholder="City"
        />
        <div className="flex gap-5">
          {isUsCustomer && (
            <Field
              name="customerInfo.state"
              component={FoundationInput}
              options={options.states}
              label="State"
              inputType="select"
              placeholder="Select State"
            />
          )}

          <Field
            name="customerInfo.zip"
            component={FoundationInput}
            label="Zip / Post Code"
            placeholder="Zip / Post Code"
          />
        </div>
        <div className="button-section">
          <div className="button-section__content">
            <SubmitButton
              className="foundation-button-primary"
              {...{ invalid, submitting }}
            >
              {editing ? 'Update and Continue' : 'Continue'}
            </SubmitButton>
          </div>
        </div>
      </div>
    </form>
  )
}

PersonalInformationForm.propTypes = propTypes

const modify = ({ onSubmit }) => {
  return {
    constraints: {
      'customerInfo.firstName': { presence: true },
      'customerInfo.lastName': { presence: true },
      'customerInfo.email': {
        presence: true,
        email: true,
        length: { maximum: 60 },
      },
      'customerInfo.phone': { presence: true, length: { maximum: 20 } },
      'customerInfo.country': {
        presence: true,
      },
      'customerInfo.address_1': {
        presence: {
          message: `^Street address can't be blank`,
        },
        length: { maximum: 50 },
      },
      'customerInfo.city': {
        presence: true,
      },
      'customerInfo.state': {
        presence: true,
      },
      'customerInfo.zip': {
        presence: true,
      },
    },
    onSubmit: (values, dispatch, props) => {
      // if backend validation or recaptcha is needed, should be added here
      return onSubmit(values, dispatch, props)
    },
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: selectors.isAuthenticated(state),
  }
}

export default compose(
  connect(mapStateToProps),
  modifyProps(modify),
  lpForm({
    name: 'foundation-cart-information',
    onSubmitFail: (params, dispatch, _, { syncErrors }) => {
      const firstErrorField = first(keys(syncErrors))
      return scroller.scrollTo(firstErrorField, {
        smooth: true,
      })
    },
    enableReinitialize: true,
  })
)(PersonalInformationForm)
