import React, { useMemo, useState } from 'react'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { selectors as userSelectors } from '../../../main/user/reducer'
import { selectors } from '../reducer'
import { Spinner } from '@launchpadlab/lp-components'
import TicketPrice from '../components/TicketPrice'
import { first, some, groupBy, orderBy, isEmpty, isNil, sumBy } from 'lodash'
import { format } from 'date-fns'
import CampsFilter from '../components/CampsFilter'
import { OrderReceipt } from '../components'
import c from 'classnames'
import TicketCartListener from '../components/TicketCartListener'
import TicketActivitiesListener from '../components/TicketActivitiesListener'
import TicketActivityTimesListener from '../components/TicketActivityTimesListener'
import {
  BabyIcon,
  CalendarIcon,
  ClockIcon,
  TentIcon,
} from '../../../components/ui/Icons'
import {getProgramName, CAMP_CATEGORY_ID} from '../utils/camps'

function isBlank(value) {
  return isEmpty(value) || isNil(value)
}

function filterByAge(camp, { age }) {
  return (
    isBlank(age) ||
    some(age, (a) => a.value >= camp.ageMin && a.value <= camp.ageMax)
  )
}

function filterByDate(camp, { date }) {
  return (
    isBlank(date) || some(date, (d) => d.value == camp.startDate.split('-')[1])
  )
}

function filterByLength(camp, { length }) {
  return isBlank(length) || some(length, (l) => l.value === camp.lengthInDays)
}

function filterByAvailability(camp, { available }) {
  return !(available && camp.soldOut)
}

// note: this code was later modified to do the same for classes so a lot of
// places where this code says "camps" should technically be "camp or class"

const FILTERS = [
  filterByAge,
  filterByDate,
  filterByLength,
  filterByAvailability,
]

const DEFAULT_FILTERS = {
  age: [],
  available: true,
  date: [],
  length: [],
}

const NoResults = () => {
  return (
    <div className="flex flex-col p-16 space-y-10 text-gray-500 bg-white shadow align-center">
      <TentIcon className="h-16" />
      <p className="text-xl font-normal text-gray-500 font-roboto">
        Whoops! Nothing matches your filters right now. Seems like our animals
        are out exploring! Try tweaking your filters or check back soon. More
        fun is always on the horizon at our zoo. See you soon!
      </p>
    </div>
  )
}

const CampCard = ({ camp, cart, activity }) => {
  const {
    ageMin,
    ageMax,
    lengthInDays,
    startTime,
    displayName,
    soldOut,
    vacancy,
  } = camp

  const price = first(camp.prices)

  const ageRangeText = `Ages ${ageMin} - ${ageMax}`

  const lineItem = cart.lineItems
    ? cart.lineItems.find(({ eventId }) => eventId === camp.id)
    : undefined

  const quantity = lineItem
    ? sumBy(
        lineItem.lineItemPrices.filter((lip) => lip.priceId === price.id),
        'quantity'
      )
    : 0

  return (
    <div
      className={c('p-5 space-y-5 bg-white shadow', {
        'border-l-8 border-solid border-brand-green': quantity > 0,
      })}
    >
      <header className="space-y-2">
        <div className="flex gap-8 text-sm font-bold">
          <div className="flex items-center gap-1">
            <BabyIcon className="w-6 h-6" />
            <span>{ageRangeText}</span>
          </div>
          <div className="flex items-center gap-1">
            <CalendarIcon className="w-6 h-6" />
            <span>
              {lengthInDays} {lengthInDays === 1 ? 'Day' : 'Days'}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <ClockIcon className="w-6 h-6" />
            <span>Start: {startTime}</span>
          </div>
        </div>
        <h1 className="m-0 text-lg font-bold text-left text-primary font-roboto">
          {displayName}
        </h1>
      </header>
      <TicketPrice
        ticketPrice={{ ...price, displayName: 'Child' }}
        ticketActivity={activity}
        ticketActivityTime={camp}
        lineItem={lineItem}
        vacancy={soldOut ? 0 : vacancy}
        restriction={ageRangeText}
        quantity={quantity}
        skipAttendees
      />
    </div>
  )
}

const SelectCamps = ({
  location,
  camps,
  isLoggedIn,
  activities,
  ticketCart,
  isGuestFlowPreferred,
}) => {
  const { cartToken, categoryId = CAMP_CATEGORY_ID } = location.query

  const {programName, pluralProgramName} = getProgramName[categoryId];
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  function clearFilters() {
    setFilters(DEFAULT_FILTERS)
  }

  const filteredCamps = useMemo(() => {
    if (!filters) return camps

    return camps
      ? camps.filter((camp) => FILTERS.every((filter) => filter(camp, filters)))
      : undefined
  }, [camps, filters])

  const campsByDate = filteredCamps
    ? groupBy(orderBy(filteredCamps, 'startDate'), 'startDate')
    : undefined

  const isContinueDisabled =
    categoryId &&
    ticketCart &&
    ticketCart.lineItems.every(
      (lineItem) => lineItem.productCategoryId !== parseInt(categoryId)
    )
  const skipSignIn = isLoggedIn || isGuestFlowPreferred
  const continueUrl = `/ticketing/camps/attendees?cartToken=${cartToken}&categoryId=${categoryId}`
  const continueUrlEncoded = encodeURIComponent(continueUrl)
  const continueSignInUrl = `/sign-in?redirectUrl=${continueUrlEncoded}`
  const signInUrl = `/sign-in?redirectUrl=${encodeURIComponent(
    `/ticketing/camps?cartToken=${cartToken}&categoryId=${categoryId}`
  )}`
  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 0 }}
      summary={<OrderReceipt />}
      title={`Choose a ${programName}`}
      onContinue={skipSignIn ? continueUrl : continueSignInUrl}
      continueDisabled={isContinueDisabled}
      introduction={
        <div className="mx-auto mt-5 space-y-5 sm:w-2/3 lg:w-1/2">
          {!skipSignIn && (
            <div className="text-2xl font-light">
              Do you have an account?
              <Link
                to={signInUrl}
                className="inline-block mx-2 text-2xl no-underline text-primary"
              >
                Sign In
              </Link>
            </div>
          )}
          <p className="mb-0 text-justify">
            Have a WILD time with Omaha's Henry Doorly Zoo {pluralProgramName}!
            Certified teachers will guide students through days packed with
            educational activities, tours, games, and much more.
          </p>
          <p className="mb-0 text-justify">
            Pre-registration and payment are required. Registration fee covers
            program participant.
          </p>
        </div>
      }
      leftAside={
        <CampsFilter
          camps={camps}
          filters={filters}
          onChange={setFilters}
          onClear={clearFilters}
        />
      }
    >
      <TicketActivityTimesListener kind="category" id={categoryId} />
      <TicketActivitiesListener />
      <TicketCartListener />
      {!filteredCamps ? (
        <Spinner />
      ) : filteredCamps.length === 0 ? (
        <NoResults />
      ) : (
        <ol className="flex-1 space-y-10">
          {Object.entries(campsByDate).map(([date, camps]) => (
            <li className="mb-0 space-y-5" key={date}>
              <header className="text-lg font-bold">
                {format(date, 'dddd, MMMM Do')}
              </header>
              <ol className="space-y-5">
                {camps.map((camp) => {
                  const activity = activities.find(
                    ({ id }) => id === camp.activityId
                  )
                  return (
                    <li key={camp.id} className="mb-0">
                      <CampCard
                        camp={camp}
                        cart={ticketCart}
                        activity={activity}
                      />
                    </li>
                  )
                })}
              </ol>
            </li>
          ))}
        </ol>
      )}
    </SocietyCheckoutLayout>
  )
}

function mapStateToProps(state) {
  const activities = selectors.ticketActivities(state)

  if (!activities) {
    return {}
  }

  return {
    camps: selectors.ticketTimes(state),
    activities,
    isLoggedIn: userSelectors.isAuthenticated(state),
    ticketCart: selectors.ticketCart(state),
    isGuestFlowPreferred: userSelectors.isGuestFlowPreferred(state),
  }
}

export default connect(mapStateToProps)(SelectCamps)
