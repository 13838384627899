import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'main/types'
import { compose } from 'redux'
import { lpForm } from '@launchpadlab/lp-form'
import { Input, Select } from '@launchpadlab/lp-components'
import {
  Field,
  propTypes as formPropTypes,
  FormSection,
} from 'redux-form'
import { RecaptchaInput } from 'components'
import * as options from 'main/options'
import { modifyProps } from '@launchpadlab/lp-hoc'
import {
  getCountryOptions,
  validateRecaptcha,
  displayCurrency,
  setZipConstraints,
  setIf,
} from 'utils'
import { GpiForm } from 'components/Gpi'
import SocietyHeading from '../../../layout/SocietyHeading'

const propTypes = {
  ...formPropTypes,
  selectedGiftCard: Types.giftCard.isRequired,
  isUsCustomer: PropTypes.bool.isRequired,
}

const defaultProps = {}

function setVariablePriceConstraints({
  minAmount = 0,
  maxAmount,
  isFixedPrice,
}) {
  // Only run the numericality if there is a value
  const initialPriceConstraints = {
    numericality: function (value) {
      // Note that the number 0 is falsey, but the value coming in here
      // is "0" which is truthy so these validations get run
      // on an input of 0 (which is what we want)

      // We want fixedPrice gift cards to be able to have 0 in the extra funds field
      const minAmountForValidations = isFixedPrice ? 0 : minAmount

      if (value) {
        if (maxAmount) {
          return {
            greaterThanOrEqualTo: minAmountForValidations,
            lessThanOrEqualTo: maxAmount,
            notGreaterThanOrEqualTo: `Value must be at least ${displayCurrency(
              minAmount
            )}`,
            notLessThanOrEqualTo: `Value cannot exceed ${displayCurrency(
              maxAmount
            )}`,
          }
        }
        return {
          greaterThanOrEqualTo: minAmountForValidations,
          notGreaterThanOrEqualTo: `Value must be at least ${displayCurrency(
            minAmount
          )}`,
        }
      }
    },
  }

  return compose(setIf(!isFixedPrice, 'presence', true))(
    initialPriceConstraints
  )
}

function GiftCardPaymentForm({ handleSubmit, selectedGiftCard, isUsCustomer }) {
  const isFixed = selectedGiftCard.isFixedPrice

  return (
    <form id="giftcard-payment" onSubmit={handleSubmit} className="space-y-10">
      <div className="p-5 bg-white shadow">
        <SocietyHeading>Gift Card Value</SocietyHeading>
        {isFixed ? (
          <p>
            <strong>Value:</strong>{' '}
            {displayCurrency(selectedGiftCard.fixedPrice)}
          </p>
        ) : (
          <Field
            name="variablePrice"
            label="Value"
            component={Input}
            type="number"
          />
        )}
        {isFixed && (
          <>
            <SocietyHeading>Add Extra Funds - Optional</SocietyHeading>
            <Field
              name="variablePrice"
              label="Enter the amount:"
              component={Input}
              type="number"
            />
          </>
        )}

        <SocietyHeading>Number of Gift Cards</SocietyHeading>
        <Field
          name="quantity"
          label="Quantity"
          component={Input}
          type="number"
        />
      </div>
      <div className="p-5 bg-white shadow">
        <SocietyHeading>Payment Details</SocietyHeading>
        <Field name="nameOnCard" component={Input} />

        <GpiForm />
        <FormSection name="address">
          <Field
            name="country"
            component={Select}
            options={getCountryOptions()}
            label="Country"
            placeholder="Select"
          />
          <Field
            name="streetAddress"
            component={Input}
            label="Street Address"
          />
          <Field name="suburb" component={Input} label="City" />
          <div className="flex gap-5">
            {isUsCustomer && (
              <Field
                name="state"
                component={Select}
                options={options.states}
                label="State"
                placeholder="Select"
              />
            )}
            <Field name="zip" component={Input} label="Zip / Post Code" />
          </div>
        </FormSection>

        <Field name="email" component={Input} />
        <Field name="phone" component={Input} type="tel" />

        <Field
          name="recaptcha"
          label={false}
          component={RecaptchaInput}
          validate={validateRecaptcha}
        />
      </div>
    </form>
  )
}

GiftCardPaymentForm.propTypes = propTypes
GiftCardPaymentForm.defaultProps = defaultProps

const modify = ({ customerCountry, selectedGiftCard, onSubmit }) => {
  return {
    constraints: {
      quantity: {
        presence: true,
        numericality: {
          greaterThanOrEqualTo: 1,
        },
      },
      variablePrice: setVariablePriceConstraints(selectedGiftCard),
      nameOnCard: { presence: true },
      'address.streetAddress': {
        presence: true,
        length: { maximum: 50 },
      },
      email: {
        presence: true,
        email: true,
        length: { maximum: 60 },
      },
      phone: { presence: true, length: { maximum: 20 } },
      'address.suburb': {
        presence: true,
        length: { maximum: 30 },
      },
      'address.country': { presence: true },
      'address.state': { presence: true },
      'address.zip': setZipConstraints(customerCountry),
    },
    isUsCustomer: customerCountry === 'US',
    onSubmit: (values, dispatch, props) => {
      return onSubmit(values, dispatch, props)
    },
  }
}

export default compose(
  modifyProps(modify),
  lpForm({
    name: 'giftcard-payment',
  })
)(GiftCardPaymentForm)
