import React from 'react'
import { Link } from 'react-router'
import progressBarCheck from 'images/progressbar-check.svg'
import { selectors } from '../../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'

function SubmissionConfirmation({ isAuthenticated }) {
  return (
    <div className="submission-confirmation">
      <div className="wrapper">
        <div className="content">
          <h2>Thank You</h2>
          <img className="w-56 m-10 mx-auto" src={progressBarCheck} />
          <p>Your gift supports the 30,000+ animals that call our Zoo home.</p>
          <p> Thank you for your suppport!</p>
        </div>

        {!isAuthenticated && (
          <div className="call-to-action">
            <p>
              Manage your donations and payment information when you sign in to
              your Omaha Zoo online account!
            </p>
            <Link className="button-green-outline" to="/give/create-account">
              Create account
            </Link>
          </div>
        )}

        {isAuthenticated ? (
          <Link className="button-green no-print" to="/give/my-account">
            View your account
          </Link>
        ) : (
          <Link className="button-green no-print" to="/give">
            Return to Website
          </Link>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isAuthenticated: selectors.isAuthenticated(state),
  }
}

export default compose(connect(mapStateToProps))(SubmissionConfirmation)
