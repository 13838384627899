export const CAMP_CATEGORY_ID = '17'
export const CLASS_CATEGORY_ID = '18'
export const SCOUT_CATEGORY_ID = '57'

export const isCampOrClass = (categoryId) => (
    categoryId == CAMP_CATEGORY_ID || categoryId == CLASS_CATEGORY_ID || categoryId == SCOUT_CATEGORY_ID
 )

 export const getProgramName = {
    [CAMP_CATEGORY_ID]: {
        programName: 'Camp', 
        pluralProgramName: 'Camps'
    },
    [CLASS_CATEGORY_ID]: {
        programName: 'Class', 
        pluralProgramName: 'Classes'
    }, 
    [SCOUT_CATEGORY_ID]: {
        programName: 'Scout Class', 
        pluralProgramName: 'Scout Classes' 
    }, 
 }
