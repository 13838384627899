import React, { useState } from 'react'
import { displayCurrency } from 'utils'
import c from 'classnames'
import { connect } from 'react-redux'
import * as actions from '../actions'
import { selectors } from '../reducer'
import TicketingCart from '../utils/ticketingCart'
import useModal from '../../../hooks/useModal'
import TicketAttendeeModal from './TicketAttendeeModal'
import ModalDialog from '../../../components/ui/ModalDialog'
import RenderedHtml from '../../../components/RenderedHtml'
import LinkButton from '../../../components/ui/LinkButton'

const TicketPriceButton = (props) => (
  <button
    className={c(
      'block text-2xl transition-colors bg-transparent border-0 cursor-pointer h-1/2 aspect-square hover:bg-primary-400 hover:text-white',
      {
        'text-primary-500': !props.disabled,
        'pointer-events-none text-gray-400': props.disabled,
      }
    )}
    {...props}
  />
)

function TicketPrice({
  quantity,
  disableAdd,
  disableRemove,
  vacancy,
  restriction,

  ticketPrice,
  ticketActivity,
  ticketActivityTime,
  lineItem,

  skipAttendees,

  // From mapStateToProps
  ticketCart,

  // From mapDispathToProps
  setTicketCart,
}) {
  const modal = useModal()

  const subtotal = ticketPrice.price * quantity

  const soldOut = vacancy <= 0

  const lineItemPrice =
    lineItem && lineItem.lineItemPrices
      ? lineItem.lineItemPrices.find(
          ({ priceId }) => priceId === ticketPrice.id
        )
      : undefined

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const ariaDescriptionId = `product-description-${ticketPrice.id}`
  const ariaLabelForReadMore = `Read more about ${ticketPrice.displayName}`

  async function handleAdd(attendee, attendeeUdfs) {
    setTicketCart({
      success: await TicketingCart.REMOTE.addLineItemPrice(
        ticketCart.token,
        ticketPrice,
        ticketActivityTime,
        ticketActivity,
        // Attendee details are scoped to the Line Item Price
        // So one line item price per item must be added in order to correctly collect attendee details
        // ticketPrice.collectAttendeeDetails ? undefined : lineItemPrice,
        undefined,
        attendee,
        attendeeUdfs
      ),
    })
  }

  async function handleRemove() {
    setTicketCart({
      success: await TicketingCart.REMOTE.removeLineItemPrice(
        ticketCart.token,
        lineItemPrice,
        lineItem
      ),
    })
  }

  return (
    <div
      className={c(
        'flex h-16 gap-2 border border-gray-100 border-solid font-frutiger',
        { 'bg-gray-100 text-gray-400': soldOut, 'bg-white': !soldOut }
      )}
    >
      <div
        className={c(
          'flex items-center justify-center h-full text-3xl text-gray-400 bg-gray-100 aspect-square',
          {
            'bg-primary-50 text-primary-600 border border-solid border-primary-600':
              quantity,
          }
        )}
      >
        {quantity}
      </div>
      <div className={'flex flex-col justify-center flex-grow p-2 space-y-1'}>
        <div className="space-x-1">
          <span>{ticketPrice.displayName}</span>
          {ticketPrice.description && ` - ${ticketPrice.description}`}
          {restriction && (
            <span className="text-sm font-bold">{restriction}</span>
          )}
        </div>
        <div className="space-x-1.5 text-sm font-bold">
          <span>{displayCurrency(ticketPrice.price)}</span>
          <span>
            {ticketPrice.infoModalText && (
              <LinkButton
                aria-describedby={ariaDescriptionId}
                aria-label={ariaLabelForReadMore}
                label="Read More"
                onClick={() => setIsInfoModalOpen(true)}
                primary
              />
            )}
          </span>
          {soldOut ? (
            <span className="text-danger-300">Sold out</span>
          ) : (
            <span className="space-x-1.5">
              {quantity > 0 && (
                <span>{`ea x${quantity} = ${displayCurrency(subtotal)}`}</span>
              )}
              {vacancy && (
                <span className="text-secondary">
                  {vacancy} spots available!
                </span>
              )}
            </span>
          )}
        </div>
      </div>
      <div className="h-full">
        <TicketPriceButton
          disabled={disableAdd || soldOut}
          onClick={
            ticketPrice.collectAttendeeDetails && !skipAttendees
              ? modal.open
              : () => handleAdd()
          }
          aria-label={`Add ${ticketPrice.displayName}`}
          aria-describedby={`ticket-${ticketPrice.id}-price`}
        >
          +
        </TicketPriceButton>
        <TicketPriceButton
          disabled={!quantity || disableRemove || soldOut}
          onClick={handleRemove}
          aria-label={`Remove ${ticketPrice.displayName}`}
          aria-describedby={`ticket-${ticketPrice.id}-price`}
        >
          -
        </TicketPriceButton>
      </div>
      {ticketActivity &&
        ticketActivity.attendeeUdfs &&
        ticketPrice &&
        ticketPrice.collectAttendeeDetails && (
          <TicketAttendeeModal
            isOpen={modal.isOpen}
            onConfirm={async (attendee, attendeeUdfs) => {
              await handleAdd(attendee, attendeeUdfs)
              modal.close()
            }}
            onClose={modal.close}
            udfs={ticketActivity.attendeeUdfs}
            eventName={ticketPrice.displayName}
          />
        )}
      {ticketPrice.infoModalText && (
        <ModalDialog
          isOpen={isInfoModalOpen}
          title={ticketPrice.displayName}
          content={<RenderedHtml>{ticketPrice.infoModalText}</RenderedHtml>}
          onClose={() => setIsInfoModalOpen(false)}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return { ticketCart: selectors.ticketCart(state) }
}

const mapDispatchToProps = {
  setTicketCart: actions.setTicketCart,
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketPrice)
