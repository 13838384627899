import React from 'react'
import c from 'classnames'
import ButtonLink from '../components/ui/ButtonLink'

const ProductAdCard = ({ads}) => {
  const ad = ads[0]
  const backgroundImage = `url(${ad.image.url})`

  return (
    <div className={c('w-full font-roboto')}>
      <section className={c('flex flex-col relative w-full h-full bg-white')}>
        <figure
          className={c(
            'relative h-full flex items-end justify-center p-5 bg-cover bg-center'
          )}
          style={{ backgroundImage, opacity: 0.5 }}
        ></figure>
        <footer className={c('p-5 absolute self-center justify-self-end bottom-0')}>

          <div className="m-0 font-bold pb-12 text-center text-2xl" id={`product-description-${ad.id}`}>
          {ad.description}
          </div>

          <ButtonLink
            aria-describedby={`product-description-${ad.id}`}
            aria-label={`Book your tickets for ${ad.description}`}
            label="Learn More"
            href={`/ticketing/details?activityId=${ad.itemId}`}
            primary
            lg
            width="fixed"
          />
        </footer>
      </section>
    </div>
  )
}

export default ProductAdCard
