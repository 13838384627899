import { useEffect } from 'react'
import * as apiActions from 'main/apiActions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const TicketTimeListener = ({ id, activityId, dispatch }) => {
  useEffect(() => {
    if (id && activityId) {
      dispatch(apiActions.fetchTicketActivityTime(activityId, id))
    }
  }, [activityId, id])

  return null
}

TicketTimeListener.propTypes = {
  id: PropTypes.number,
  activityId: PropTypes.string,
  dispatch: PropTypes.func,
}
export default connect()(TicketTimeListener)
