import React from 'react'
import { connect } from 'react-redux'
import * as flashActions from 'redux-flash'
import { format } from 'date-fns'
import { compact } from 'lodash'
import { Link } from 'react-router'
import Barcode from 'react-jsbarcode'
import { isEmpty, displaySubmitFailure } from 'utils'

import * as effects from 'main/effects'
import MobileWalletButtons from 'main/membership/components/MembershipMobileWalletButtons'
import SocietyPageLayout from 'layout/SocietyPageLayout'
import SocietyHeading from 'layout/SocietyHeading'
import Anchor from 'components/ui/Anchor'
import societyLogo from 'images/logo.svg'

import { selectors as membershipSelectors } from '../../membership/reducer'
import * as membershipActions from '../../membership/actions'
import { selectors as userSelectors } from '../../user/reducer'
import { AccountInfoForm } from '../forms'

function AccountInfo({
  primaryMember,
  secondaryMember,
  childMembers,
  nannyMember,
  guestMembers,
  setMembershipDetails,
  flashSuccessMessage,
}) {
  const initialValues = {
    primaryMember: {
      ...primaryMember,
      id: primaryMember.id,
      isPrimary: primaryMember.isPrimary,
      firstName: primaryMember.firstName,
      lastName: primaryMember.lastName,
      dateOfBirth: primaryMember.dateOfBirth || '',
      email: primaryMember.email,
      phone: primaryMember.phone,
      address: {
        streetAddress1: primaryMember.address.streetAddress,
        city: primaryMember.address.suburb,
        state: primaryMember.address.state,
        zip: primaryMember.address.zip,
        country: primaryMember.address.country || 'US',
      },
      allowEditName: primaryMember.allowEditName, //api unifinished, always returns false
      allowEditAddress: primaryMember.allowEditAddress, //api unifinished, always returns true
      allowEditPhone: primaryMember.allowEditPhone, //api unifinished, always returns true
      allowEditEmail: primaryMember.allowEditEmail, // is true if email is blank
    },
    ...(!isEmpty(secondaryMember) && {
      secondaryMember: {
        id: secondaryMember.id,
        isSecondary: secondaryMember.isSecondary,
        firstName: secondaryMember.firstName,
        lastName: secondaryMember.lastName,
        dateOfBirth: secondaryMember.dateOfBirth || '',
        currentMembershipType: secondaryMember.currentMembershipType,
        allowEditName: secondaryMember.allowEditName,
      },
    }),
    childMembers: childMembers.map((childMember) => ({
      id: childMember.id,
      isChild: childMember.isChild,
      firstName: childMember.firstName,
      lastName: childMember.lastName,
      dateOfBirth: childMember.dateOfBirth || '',
      currentMembershipType: childMember.currentMembershipType,
      allowEditName: childMember.allowEditName,
    })),
    ...(!isEmpty(nannyMember) && {
      nannyMember: {
        id: nannyMember.id,
        isNanny: nannyMember.isNanny,
        firstName: nannyMember.firstName,
        lastName: nannyMember.lastName,
        dateOfBirth: nannyMember.dateOfBirth || '',
        currentMembershipType: nannyMember.currentMembershipType,
        allowEditName: nannyMember.allowEditName,
      },
    }),
    guestMembers: guestMembers.map((guestMember) => ({
      id: guestMember.id,
      firstName: guestMember.firstName,
      lastName: guestMember.lastName,
      dateOfBirth: guestMember.dateOfBirth || '',
      allowEditName: guestMember.allowEditName,
    })),
  }

  const formatMembershipDate = (date) => format(date, 'MMMM Do, YYYY')
  const formatExpirationpDate = (date) => format(date, 'MM.DD.YY')

  const { currentMembership } = primaryMember

  const displayMembershipCard =
    currentMembership && process.env.REACT_APP_MEMBER_CARD === 'true'
  const displayMembershipSummary = !displayMembershipCard && currentMembership

  return (
    <SocietyPageLayout
      title="Account Information"
      introduction={
        <Anchor
          as={Link}
          to="/my-account/order-history"
          label="Order History"
          primary
          lg
        />
      }
    >
      {displayMembershipSummary && (
        <div className="p-5 bg-white shadow">
          <SocietyHeading>Membership Details</SocietyHeading>
          <div className="text-lg font-bold">
            {currentMembership.package} Membership
          </div>
          <div className="text-sm italic">
            Valid from{' '}
            <span className="underline">
              {formatMembershipDate(currentMembership.joinDate)}
            </span>{' '}
            through{' '}
            <span className="underline">
              {formatMembershipDate(currentMembership.expirationDate)}
            </span>
          </div>
        </div>
      )}
      {displayMembershipCard && (
        <div className="p-5 bg-white shadow">
          <SocietyHeading>Membership Details</SocietyHeading>
          <div className="border-dashed border-2 max-w-md w-full rounded-lg">
            <div className="clear-right p-3">
              <div
                className=" float-left w-40 h-20 justify-center bg-contain bg-center bg-no-repeat mr-4"
                style={{ backgroundImage: `url(${societyLogo})` }}
              />

              <div className="mx-4 p-2 text-md h-20 text-left">
                <div className="p-2 font-bold text-lg">
                  {primaryMember.firstName} {primaryMember.lastName}
                </div>
                <div className="">{currentMembership.package} Membership</div>
              </div>
            </div>

            <div className="flex justify-center items-center ">
              <Barcode
                value={primaryMember.memberNumber}
                options={{ format: 'code128', height: 40, width: 3 }}
                renderer="svg"
              />
            </div>
            <div className="text-right m-4">
              Expires: {formatExpirationpDate(currentMembership.expirationDate)}
            </div>
          </div>

          <div className="py-2 text-sm italic max-w-md w-full">
            Valid from{' '}
            <span className="underline">
              {formatMembershipDate(currentMembership.joinDate)}
            </span>{' '}
            through{' '}
            <span className="underline">
              {formatMembershipDate(currentMembership.expirationDate)}
            </span>
          </div>
          <MobileWalletButtons />
        </div>
      )}
      <AccountInfoForm
        initialValues={initialValues}
        onSubmit={(values) => {
          const members = compact([
            values.primaryMember,
            values.secondaryMember,
            ...values.childMembers,
            values.nannyMember,
            ...values.guestMembers,
          ])
          return effects.updateMembers(members)
        }}
        onSubmitSuccess={({ data: { attributes } }) => {
          setMembershipDetails(attributes)
          flashSuccessMessage(
            'Your account information was updated successfully!'
          )
        }}
        onSubmitFail={displaySubmitFailure}
      />
    </SocietyPageLayout>
  )
}

function mapStateToProps(state) {
  return {
    primaryMember:
      userSelectors.primaryMember(state) ||
      membershipSelectors.primaryMember(state),
    secondaryMember: membershipSelectors.secondaryMember(state),
    childMembers: membershipSelectors.childMembers(state),
    nannyMember: membershipSelectors.nannyMember(state),
    guestMembers: membershipSelectors.guestMembers(state),
  }
}

const mapDispatchToProps = {
  setMembershipDetails: membershipActions.setMembershipDetails,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo)
