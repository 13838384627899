import { useEffect } from 'react'
import * as apiActions from 'main/apiActions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const today = new Date()

const TicketActivityTimesListener = ({
  kind = 'activity',
  id,
  startDate = today,
  endDate = undefined,
  dispatch,
}) => {
  useEffect(() => {
    if (id) {
      dispatch(
        apiActions.fetchTicketActivityTimes(id, {
          type: kind,
          startDate,
          endDate,
        })
      )
    }
  }, [id, kind, startDate, endDate])

  return null
}

TicketActivityTimesListener.propTypes = {
  kind: PropTypes.string,
  id: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  dispatch: PropTypes.func,
}
export default connect()(TicketActivityTimesListener)
